var combineScript = combineScript || {};

/**
 * Simple view-library to organise our different widgets/views/directives.
 *
 * Widgets allow you to hook some behaviour to a DOM element by adding a certain data-attribute to a HTML tag. For
 * example, adding the data-carousel attribute to an element, will link the carousel behaviour to it.
 */
combineScript.Widgets = (function(window, $)
{
    // { 'name' : object }
    var widgets = {};

    function linkWidgets($container)
    {
        $container = $container || $(document);

        // find all directives in $container and if not linked, link them
        $.each(widgets, function(name, widget)
        {
            var $elements = $("[data-" + name + "]", $container);

            $.each($elements, function(index, element)
            {
                var $element = $(element);

                // Don't relink widget, if it is already linked
                var linked = $element.attr('data-linked');
                if (linked != undefined && linked.indexOf(name) > -1) {
                    return;
                }

                widget.linkFunction($, $element, $element.data());
                widget.linked.push($element);
                linked = (linked ? linked + ' ' + name : name);
                $element.attr('data-linked', linked);
            });
        });
    }

    function removeLinkedAttr($container)
    {
        $container = $container || $(document);

        $.each(widgets, function()
        {
            var $elements = $("[data-linked]", $container);

            $.each($elements, function(index, element) {
                var $element = $(element);
                $element.removeAttr('data-linked');
            });
        });
    }

    /**
     * Create a new widget. The 'name' is the hook which will be used to link it to the html element.
     * A widget called 'dropdown' will be linked to all tags which have the 'data-dropdown' attribute;
     *
     * @param name
     * @param linkFunction
     */
    function add(name, linkFunction)
    {
        widgets[name] = {
            linkFunction: linkFunction, // link functions
            linked: [] // linked elements
        };
    }

    return {
        linkWidgets: linkWidgets,
        removeLinkedAttr: removeLinkedAttr,
        add: add
    };

})(window, jQuery);

/*! http://responsiveslides.com v1.55 by @viljamis */
var combineScript = combineScript || {};

combineScript.Widgets.add('carousel', function($, $element, properties) {

(function(c,K,C){c.fn.responsiveSlides=function(m){var a=c.extend({auto:!0,speed:500,timeout:4E3,pager:!1,nav:!1,random:!1,pause:!1,pauseControls:!0,prevText:"Previous",nextText:"Next",maxwidth:"",navContainer:"",manualControls:"",namespace:"rslides",before:c.noop,after:c.noop},m);return this.each(function(){C++;var f=c(this),u,t,v,n,q,r,p=0,e=f.children(),D=e.length,h=parseFloat(a.speed),E=parseFloat(a.timeout),w=parseFloat(a.maxwidth),g=a.namespace,d=g+C,F=g+"_nav "+d+"_nav",x=g+"_here",k=d+"_on",
y=d+"_s",l=c("<ul class='"+g+"_tabs "+d+"_tabs' />"),z={"float":"left",position:"relative",opacity:1,zIndex:2},A={"float":"none",position:"absolute",opacity:0,zIndex:1},G=function(){var b=(document.body||document.documentElement).style,a="transition";if("string"===typeof b[a])return!0;u=["Moz","Webkit","Khtml","O","ms"];var a=a.charAt(0).toUpperCase()+a.substr(1),c;for(c=0;c<u.length;c++)if("string"===typeof b[u[c]+a])return!0;return!1}(),B=function(b){a.before(b);G?(e.removeClass(k).css(A).eq(b).addClass(k).css(z),
p=b,setTimeout(function(){a.after(b)},h)):e.stop().fadeOut(h,function(){c(this).removeClass(k).css(A).css("opacity",1)}).eq(b).fadeIn(h,function(){c(this).addClass(k).css(z);a.after(b);p=b})};a.random&&(e.sort(function(){return Math.round(Math.random())-.5}),f.empty().append(e));e.each(function(a){this.id=y+a});f.addClass(g+" "+d);m&&m.maxwidth&&f.css("max-width",w);e.hide().css(A).eq(0).addClass(k).css(z).show();G&&e.show().css({"-webkit-transition":"opacity "+h+"ms ease-in-out","-moz-transition":"opacity "+
h+"ms ease-in-out","-o-transition":"opacity "+h+"ms ease-in-out",transition:"opacity "+h+"ms ease-in-out"});if(1<e.length){if(E<h+100)return;if(a.pager&&!a.manualControls){var H=[];e.each(function(a){a+=1;H+="<li><a href='#' class='"+y+a+"'>"+a+"</a></li>"});l.append(H);m.navContainer?c(a.navContainer).append(l):f.after(l)}a.manualControls&&(l=c(a.manualControls),l.addClass(g+"_tabs "+d+"_tabs"));(a.pager||a.manualControls)&&l.find("li").each(function(a){c(this).addClass(y+(a+1))});if(a.pager||a.manualControls)r=
l.find("a"),t=function(a){r.closest("li").removeClass(x).eq(a).addClass(x)};a.auto&&(v=function(){q=setInterval(function(){e.stop(!0,!0);var b=p+1<D?p+1:0;(a.pager||a.manualControls)&&t(b);B(b)},E)},v());n=function(){a.auto&&(clearInterval(q),v())};a.pause&&f.hover(function(){clearInterval(q)},function(){n()});if(a.pager||a.manualControls)r.bind("click",function(b){b.preventDefault();a.pauseControls||n();b=r.index(this);p===b||c("."+k).queue("fx").length||(t(b),B(b))}).eq(0).closest("li").addClass(x),
a.pauseControls&&r.hover(function(){clearInterval(q)},function(){n()});if(a.nav){g="<a href='#' class='"+F+" carousel__nav carousel__nav--prev prev'>"+a.prevText+"</a><a href='#' class='"+F+" carousel__nav carousel__nav--next next'>"+a.nextText+"</a>";m.navContainer?c(a.navContainer).append(g):f.after(g);var d=c("."+d+"_nav"),I=d.filter(".prev");d.bind("click",function(b){b.preventDefault();b=c("."+k);if(!b.queue("fx").length){var d=e.index(b);b=d-1;d=d+1<D?p+1:0;B(c(this)[0]===I[0]?b:d);(a.pager||a.manualControls)&&t(c(this)[0]===I[0]?b:d);a.pauseControls||n()}});
a.pauseControls&&d.hover(function(){clearInterval(q)},function(){n()})}}if("undefined"===typeof document.body.style.maxWidth&&m.maxwidth){var J=function(){f.css("width","100%");f.width()>w&&f.css("width",w)};J();c(K).bind("resize",function(){J()})}})}})(jQuery,this,0);

    $(function() {
        $(".rslides").responsiveSlides({
            nav: true,
            timeout: 8000,
            prevText: "<",
            pause: true,
            nextText: ">",
        });

    });
});

const colors = ["#6074b3", "#a2b7ff", "#2a3659", "#0568a6", "#93c6e6"];
const numBalls = 350;
const balls = [];
var combineScript = combineScript || {};
var cells = document.getElementById("cells");

combineScript.Widgets.add('cells', function($, $element, properties) {

for (let i = 0; i < numBalls; i++) {
      let ball = document.createElement("div");
      ball.classList.add("ball");
      ball.style.background = colors[Math.floor(Math.random() * colors.length)];
      ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
      ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
      ball.style.transform = `scale(${Math.random()})`;
      ball.style.filter = `blur(${Math.random()})`;
      ball.style.width = `${Math.random()}em`;
      ball.style.height = ball.style.width;

      balls.push(ball);
      cells.appendChild(ball);
    }

    // Keyframes
balls.forEach((el, i, ra) => {
    let to = {
      x: Math.random() * (i % 2 === 0 ? -11 : 11),
      y: Math.random() * 12
    };

    let anim = el.animate(
      [
        { transform: "translate(0, 0)" },
        { transform: `translate(${to.x}rem, ${to.y}rem)` }
      ],
      {
        duration: (Math.random() + 1) * 2000, // random duration
        direction: "alternate",
        fill: "both",
        iterations: Infinity,
        easing: "ease-in-out"
      }
    );
  });
});


var combineScript = combineScript || {};

combineScript.Widgets.add('drawer', function($, $element, properties) {

    const $document = $(document);

    function menuOpen() {
        if (!$element.hasClass('is-active')) {
            requestAnimationFrame((function () {
                $element.addClass('is-active');

                requestAnimationFrame((function () {
                    $element.addClass('is-open');
                }).bind(this));
            }).bind(this));
        }
    }

    function menuClose() {
        if ($element.hasClass('is-active')) {
            $element.removeClass('is-open');
            setTimeout((function () {
                $element.removeClass('is-active');
            }).bind(this), 200);
        }
    }

    $document.find('.open-menu').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        menuOpen();
    });

    $element.find('.close-menu').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        menuClose();
    });
});

var combineScript = combineScript || {};

combineScript.app = (function(window, $)
{
    $(function() {
        // External source svg polyfill
        // svg4everybody();

        // Link Widgets
        combineScript.Widgets.linkWidgets();

        // Animate inline navigation
        $('.site__main a[href^="#"]:not([data-no-scroll])').click(function(e) {
            e.preventDefault();
            e.stopPropagation();

            const href = $(this).attr('href');
            combineScript.Helpers.scrollTo(href, true);
        });

        // Scroll to target if page-url contains a hash
        const hash = window.location.hash;
        const regex = /^#[a-zA-Z0-9-_]+$/;
        if (regex.test(hash)) {
            combineScript.Helpers.scrollTo(hash);
        }

        // Add target _blank to external links
        $('a[href^="http"]:not([href*="' + location.host + '"])')
            .attr('target', '_blank')
            .attr('rel', 'noopener')
        ;
    });

})(window, jQuery);
